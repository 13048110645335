<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="card p-5 mx-auto mt-4 text-center">
          <i class="fad fa-5x text-primary" :class="icon || activeRoute.icon"></i>
          <h3 class="mt-4">
            {{ headline || "Leg' jetzt los!" }}
          </h3>
          <slot>
            <p>
              Erstelle deine ersten Inhalte und erhalte wichtige Optimierungsideen!
            </p>
            <div>
              <router-link v-if="link" class="btn btn-primary btn-lg mt-4" :to="link">
                {{ label }}
              </router-link>
              <button v-else @click="$emit('click')" class="btn btn-primary btn-lg mt-4">
                {{ label }}
              </button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroBanner',
  components: {},
  directives: {},
  props: {
    hideButtons: Boolean,
    icon: String,
    headline: String,
    link: String,
    btnText: String
  },
  data() {
    return {}
  },
  computed: {
    label() {
      return this.btnText || ("Jetzt " + this.activeRoute.title + " erstellen")
    }
  },
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.fad {
  transition: color .5s;
}
</style>