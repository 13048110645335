<template>
  <div class="limbic-map-wrapper" :id="'limbic-map-' + id" :class="small ? 'small' : ''">
    <div class="limbic-map-overlay">

      <div class="limbic-map-areas">
        <div v-for="(type, index) in propTypes.filter(t => t.tags.length > 0)"
             class="limbic-map-area prop-type"
             :key="index"
             :style="
             ('top:' + type.area.y + '%;left:' + type.area.x + '%;width:' + type.area.w + '%;height:' + type.area.h + '%;') +
              (type.color ? ('background: radial-gradient(closest-side, ' + type.color + '77, ' + type.color + '77);') : '') +
              'color:' + type.color"
        >
          <div class="type h5">{{ type.type }}</div>
          <div class="name h5">{{ type.name }}</div>
        </div>

        <div v-if="selectedType.tags.length > 0"
             class="limbic-map-area"
             :style="'top:' + selectedArea.y + '%;left:' + selectedArea.x + '%;width:' + selectedArea.w + '%;height:' + selectedArea.h + '%;' +
              'background: radial-gradient(closest-side, ' + selectedColor + '77, ' + selectedColor + '77);' +
              'color:' + selectedColor"
        >
          <div v-if="selectedName" class="type h5">{{ selectedName }}</div>
          <div v-if="selectedType.name" class="name h5">{{ selectedType.name }}</div>
        </div>
      </div>

      <div v-if="!small" class="limbic-map-tags">
        <div v-for="(tag, index) in tags"
             @click="toggleTag(tag)"
             class="limbic-map-tag"
             :class="selectedType.tags.find(t => t.name === tag.name) ? 'active' : ''"
             :style="'top:' + getPosition(tag).top + '%;left:' + getPosition(tag).left + '%'"
             :key="index">
          {{ tag.name }}
        </div>
      </div>

      <div class="limbic-map-labels">
        <div v-for="(label, index) in labels"
             class="limbic-map-label"
             :style="'top:' + getPosition(label).top + '%;left:' + getPosition(label).left + '%;' + 'background:' + label.color"
             :key="index">
          <span v-if="!small">{{ label.name }}</span><span v-else>&nbsp;</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LimbicMap',
  components: {},
  directives: {},
  props: {
    small: Boolean,
    types: Array,
    addType: Object
  },
  data() {
    return {
      id: this.generateId(),
      selectedType: {
        tags: [],
      },
    }
  },
  computed: {
    tags() {
      return this.$store.state.limbicMap.tags;
    },
    labels() {
      return this.$store.state.limbicMap.labels.map(l => {
        l.color = this.getColor([{ r: l.r, d: 80 }]);
        return l;
      });
    },
    selectedArea() {
      return this.getArea(this.selectedType.tags);
    },
    selectedColor() {
      return this.getColor(this.selectedType.tags);
    },
    selectedName() {
      return this.getGeneralType(this.selectedType.tags);
    },
    propTypes() {
      return this.types.map(type => {
        type.area = this.getArea(type.tags);
        type.color = this.getColor(type.tags);
        type.type = this.getGeneralType(type.tags);
        return type;
      });
    }
  },
  watch: {},
  methods: {
    getPosition(polar) {
      let deg = (polar.r - 25) / 100 * 360;
      let rad = deg * Math.PI / 180;
      return { top: 50 + (polar.d * Math.sin(rad)) / 2, left: 50 + (polar.d * Math.cos(rad)) / 2 }
    },
    getArea(tags) {
      let margin = 1 + 5 / tags.length;
      let tagCoordinates = tags.reduce((acc, t) => {
        return {
          xMin: Math.min(this.getPosition(t).left - margin, acc.xMin),
          xMax: Math.max(this.getPosition(t).left + margin, acc.xMax),
          yMin: Math.min(this.getPosition(t).top - margin, acc.yMin),
          yMax: Math.max(this.getPosition(t).top + margin, acc.yMax),
        }
      }, { xMin: 100, xMax: 0, yMin: 100, yMax: 0 });
      return {
        x: parseFloat((tagCoordinates.xMin).toFixed(2)),
        y: parseFloat((tagCoordinates.yMin).toFixed(2)),
        w: parseFloat((tagCoordinates.xMax - tagCoordinates.xMin).toFixed(2)),
        h: parseFloat((tagCoordinates.yMax - tagCoordinates.yMin).toFixed(2)),
      }
    },
    getColor(tags) {
      let area = this.getArea(tags);

      let center = {
        x: ((area.x + area.w / 2) - 50),
        y: (-(area.y + area.h / 2) + 50)
      };

      let r = 360 - (Math.atan2(-center.y, -center.x) + Math.PI) * 180 / Math.PI;
      let d = tags.reduce((acc, tag) => acc + tag.d, 0) / tags.length;

      let h = (20 + Math.round(r)) % 360;
      let s = Math.round(d);
      let l = 60;

      l /= 100;
      let a = s * Math.min(l, 1 - l) / 100;
      let f = n => {
        let k = (n + h / 30) % 12;
        let color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
      };

      return `#${f(0)}${f(8)}${f(4)}`;
    },
    getGeneralType(tags) {
      let area = this.getArea(tags);

      let center = {
        x: ((area.x + area.w / 2) - 50),
        y: (-(area.y + area.h / 2) + 50)
      };

      let r = ((360 - (Math.atan2(-center.y, -center.x) + Math.PI) * 180 / Math.PI) / 3.6 + 25) % 100;

      let typeRanges = {
        92.85: "Abenteuerlustig",
        78.57: "Hedonistisch",
        64.28: "Offen",
        50.01: "Harmonisch",
        35.71: "Traditionell",
        21.42: "Diszipliniert",
        7.14: "Ehrgeizig",
        0.01: "Abenteuerlustig",
      }

      let type = "";
      for (let key in typeRanges) {
        if (r > key) {
          type = typeRanges[key];
          break;
        }
      }
      if (type === "") type = Object.values(typeRanges)[0];
      return type;
    },
    toggleTag(tag) {
      let index = this.selectedType.tags.findIndex(t => t.name === tag.name);
      if (index >= 0) {
        this.selectedType.tags.splice(index, 1);
      } else {
        this.selectedType.tags.push(tag)
      }
    }
  },
  beforeMount() {
    if (this.addType) {
      this.selectedType = this.addType;
    }
  },
  mounted() {
    let mapEl = document.getElementById('limbic-map-' + this.id);
    if (mapEl) {
      let win = window,
          doc = document,
          docElem = doc.documentElement,
          body = doc.getElementsByTagName('body')[0];

      let f = () => {
        let width = win.innerWidth || docElem.clientWidth || body.clientWidth;
        if (width < 768) {
          mapEl.style.fontSize = "8px";
        } else if (width < 1240) {
          mapEl.style.fontSize = "12px";
        } else {
          mapEl.style.fontSize = "14px";
        }
      }

      f();
      window.addEventListener('resize', f);
    }
  }
}
</script>

<style scoped lang="scss">
.limbic-map-wrapper {
  position: relative;
  font-size: .8rem;
  width: 100%;
  padding-top: 66%;
  color: white;

  .limbic-map-overlay {
    background: #fffffff0;
    border-radius: 100%;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    bottom: 8%;
    z-index: 5;

    .limbic-map-areas .limbic-map-area {
      position: absolute;
      border-radius: 100%;
      background: radial-gradient(closest-side, #ff3136cc, #ff313688, #ff313601);
      z-index: 6;
      pointer-events: none;
      text-align: center;

      .name {
        position: relative;
        top: calc(50% - 3rem);
        color: #222;
      }
      .type {
        position: relative;
        top: -2rem;
        text-shadow: 0 0 4px white;
      }
    }
    .limbic-map-tag, .limbic-map-label {
      position: absolute;
      background: rgba(0,255,0,.01);
      transform: translate(-50%,-50%);

      &:hover, &.active {
        font-weight: bold;
      }
    }
    .limbic-map-tag {
      z-index: 3;
      color: #333333;
    }
    .limbic-map-label {
      padding: 1% 2%;
      border-radius: 5px;
      font-weight: bold;
      pointer-events: none;
      z-index: 6;
    }
  }

  &.small {
    font-size: .4rem;

    .limbic-map-overlay {
      .limbic-map-label {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        font-weight: normal;
      }
      .limbic-map-areas .limbic-map-area {
        .type {
          font-size: .5rem;
          top: 50%;
          transform: translateY(-50%);
        }
        .name {
          display: none;
        }
      }
    }
  }
}
</style>