<template>
  <div id="personas-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/personas/add" class="btn btn-primary mb-5">Persona hinzufügen</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calculations</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
    </ul>-->

    <IntroBanner v-if="!(personas && personas.length)" link="/personas/add"></IntroBanner>
    
    <div v-for="(persona, index) in personas" class="card mb-5" :key="index">
      <div class="edit-button">
        <router-link :to="'/personas/edit/' + persona.id"><i class="fad fa-pencil"></i></router-link>
      </div>
      <div class="remove-button" @click="remove(persona.id)">
        <i class="fas fa-times"></i>
      </div>
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-sm-2">
            <img :src="img(persona.image)" alt="" class="img-fluid">
          </div>
          <div class="col-xs-10 col-sm">
            <h2>
              {{ persona.name }}, {{ persona.age }}
              <i v-if="persona.title" class="text-muted small">- {{ persona.title }}</i>
              <i v-else-if="persona.jobs[0]" class="text-muted small">- {{ persona.jobs[0].title }}</i>
            </h2>
            <p class="lead mb-0">{{ persona.motto }}</p>
          </div>
          <div class="col-2">
            <LimbicMap :types="[persona.limbic]" :small="true"></LimbicMap>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4 border-right">
            <h3 class="h4">Haupt-Jobs</h3>
            <ul class="fa-ul">
              <li v-for="(job, index) in persona.jobs" :key="index">
                <span class="fa-li"><i class="fad fa-long-arrow-right text-primary"></i></span>
                <router-link :to="'/jobs/' + job.id">{{ job.title }}</router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-4 border-right">
            <h3 class="h4">Haupt-Vorteile</h3>
            <ul v-if="persona.gains.length > 0" class="fa-ul">
              <li v-for="(gain, index) in persona.gains" :key="index">
                <span class="fa-li"><i class="fad fa-plus text-success"></i></span> {{ gain }}
              </li>
            </ul>
            <div v-else>
              <p class="text-muted">Keine Gains hinzugefügt...</p>
              <router-link :to="'/personas/edit/' + persona.id" class="btn btn-primary">Gains hinzufügen</router-link>
            </div>
          </div>
          <div class="col-sm-4">
            <h3 class="h4">Haupt-Bedenken</h3>
            <ul v-if="persona.pains.length > 0" class="fa-ul">
              <li v-for="(pain, index) in persona.pains" :key="index"><span class="fa-li">
                <i class="fad fa-minus text-danger"></i></span> {{ pain }}
              </li>
            </ul>
            <div v-else>
              <p class="text-muted">Keine Pains hinzugefügt...</p>
              <router-link :to="'/personas/edit/' + persona.id" class="btn btn-primary">Pains hinzufügen</router-link>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <!--<div class="col-sm-4 border-right">
            <h3 class="h4">Values</h3>
            <ul class="fa-ul">
              <li v-for="(tag, index) in persona.limbic.tags" :key="index"><span class="fa-li"><i class="fad fa-star text-warning"></i></span> {{ tag.name }}</li>
            </ul>
          </div>-->
          <div class="col-sm-6 border-right">
            <h3 class="h4">Kaufverhalten</h3>
            <ul class="fa-ul">
              <li v-for="(behaviour, index) in persona.buyingBehaviour" :key="index"><span class="fa-li"><i class="fad fa-money-bill text-success"></i></span> {{ behaviour }}</li>
            </ul>
          </div>
          <div class="col-sm-6">
            <h3 class="h4">Haupt-Produkte</h3>
            <ul class="fa-ul">
              <li v-for="(product, index) in persona.products" :key="index"><span class="fa-li"><i class="fad fa-paperclip text-info"></i></span> {{ product }}</li>
            </ul>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-6 border-right">
            <h3>Relevante Unique Value Propositions</h3>
            <ul v-if="persona.valueProps.length > 0" class="fa-ul">
              <li v-for="(valueProp, index) in persona.valueProps" :key="index">
                <span class="fa-li"><i class="fad fa-sparkles text-primary"></i></span>
                {{ valueProp }}
              </li>
            </ul>
            <div v-else>
              <p class="text-muted">Keine UVPs hinzugefügt...</p>
              <router-link :to="'/personas/edit/' + persona.id" class="btn btn-primary">UVPs hinzufügen</router-link>
            </div>
          </div>
          <div class="col-sm-6">
            <h3>Eigenarten</h3>
            <ul class="fa-ul">
              <li v-for="(consideration, index) in persona.consideration" :key="index">
                <span class="fa-li"><i class="fad fa-question-circle"></i></span>
                {{ consideration }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <h3>Persönliche Geschichte</h3>
        <div v-if="persona.story">
          <p>{{ persona.story }}</p>
        </div>
        <div v-else>
          <p class="text-muted">Keine Story vorhanden...</p>
          <router-link :to="'/personas/edit/' + persona.id" class="btn btn-primary">Story hinzufügen</router-link>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-4 border-right">
            <h3 class="h4">Family</h3>
            <ul>
              <li v-for="(family, index) in persona.family" :key="index">{{ family }}</li>
            </ul>
            <h3 class="h4">Lifestyle</h3>
            <ul>
              <li v-for="(lifestyle, index) in persona.lifestyle" :key="index">{{ lifestyle }}</li>
            </ul>
          </div>
          <div class="col-sm-4 border-right">
            <h3 class="h4">Hobbies</h3>
            <ul>
              <li v-for="(hobby, index) in persona.hobbys" :key="index">{{ hobby }}</li>
            </ul>
          </div>
          <div class="col-sm-4">
            <h3 class="h4">Beruf</h3>
            <ul>
              <li v-for="(occupation, index) in persona.occupation" :key="index">{{ occupation }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="personas.length" class="row">
      <div class="col-12">
        <LimbicMap :types="limbicTypes" :small="false"></LimbicMap>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import LimbicMap from "@/components/LimbicMap";
import IntroBanner from "@/components/IntroBanner";

export default {
  name: 'Personas',
  components: {
    IntroBanner,
    LimbicMap
  },
  data() {
    return {
    }
  },
  computed: {
    personas() {
      return this.project.personas.map(persona => {
        persona.jobs = persona.jobIds.map(jobId => this.project.jobs.find(j => j.id === jobId));
        return persona;
      });
    },
    limbicTypes() {
      return this.personas.map(persona => {
        return persona.limbic;
      })
    }
  },
  methods: {
    remove(id) {
      let persona = this.project.personas.find(j => j.id === id);
      if (confirm('Remove "' + persona.name + '" from Personas?')) {
        let index = this.project.personas.findIndex(p => p.id === id);
        this.project.personas.splice(index, 1);

        this.$store.dispatch('project/updateProjectByProp', {prop: 'personas', data: this.project.personas})
            .then(res => {
              console.debug(res);
            });
      }
    },
  },
  beforeMount() {
  }
}
</script>